body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select:none;
    /*background-color: rgba(17, 43, 138, 0.07);*/
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header{
    height: 50px;
    line-height: 50px;
    background-color: rgba(255, 255, 255, 0.91);
    color: #fc4544;
    font-size: 20px;
    padding-left: 20px;
    font-weight: bold;
    box-shadow: 3px 3px rgba(255, 255, 255, 0.96), -1em 0 0.4em #b4b4b4;
}

.btn_save_no_active,
.btn_remove_no_active,
.btn_save,
.btn_update,
.btn_remove,
.btn_add,
.btn_redact{
    display: inline-block;
    background-color: #D6431A;
    color:white;
    border:3px solid #73240e;
    height: 20px;
    border-radius: 10px;
    cursor: pointer;
    margin: 10px;
    padding: 10px;
    min-width: 160px;
    text-align: center;
    line-height: 20px;
    font-weight: bold;
}
.btn_remove_no_active{
    background-color: rgba(168, 100, 116, 0.9);
    border-color: rgba(115, 68, 80, 0.8);
    color: rgba(255, 255, 255, 0.55);
    cursor: default;
}
.btn_save_no_active{
    background-color: rgba(0, 128, 0, 0.6);
    border-color: darkgreen;
    color: rgba(255, 255, 255, 0.8);
    cursor: default;
}
.btn_save{
    background-color: #2caf49;
    border-color: darkgreen;
}

/*.btn_update{*/
/*    background-color: #3cadff;*/
/*    border-color: #152bc5;*/
/*}*/
.btn_update{
    background-color: rgba(96, 180, 56, 0.94);
    border-color: rgba(60, 147, 19, 0.94);
}

.btn_remove{
    background-color: #a8586a;
    border-color: #734450;
}

.btn_add{
    background-color: #4C8A0A;
    border-color: #305707;
}

input{
    width: 150px;
    height: 15px;
    line-height: 15px;
    padding: 10px;
    margin: 10px;
    border: 2px solid #3b3a3a;
    border-radius: 20px;
}

.wrapper_body{

}