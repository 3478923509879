.wrapper{
    max-width: 700px;
    margin: 30px auto;
    background-color: #ffa80b;
    padding: 10px;
    border-radius: 20px;
}

.title{
    font-size: 26px;
    font-weight: bold;
    margin: 10px 20px;
}

.title_field{
    font-size: 16px;
    font-weight: bold;
    margin: 10px 20px 0 20px;
}

.select_type_user{
    font-size: 16px;
    font-weight: bold;
    margin: 5px 20px 20px 20px;
    padding: 10px;

}
.wrapper_input{
    display: inline-block;
}

.message_user_report{
    color:#112B8A;
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
}