.online,
.offline{
    width: 20px;
    height: 20px;
    background-color: red;
    position: absolute;
    right: 15px;
    top: 15px;
    border-radius: 20px;
}
.online{
    background-color: green;
}
.icon_wrapper{
    display: inline-block;
    vertical-align: middle;
}
.setting_play,
.setting{
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    top:-10px;
    right: -10px;
    width: 30px;
    margin: 20px 20px 0 20px ;
    cursor: pointer;
    transform: scale(-1, 1)
}
/*.setting_play{*/
/*    margin: 0 20px 0 20px ;*/
/*}*/
.game_card{
    margin: 20px auto;
    padding: 10px;
    border: 2px solid #0431D6;
    background-color: rgba(4, 49, 214, 0.11);
    border-radius: 20px;
    max-width: 820px;
}
.icon_play{
    display: inline-block;
    vertical-align: middle;
}

.game_info_wrapper{
    display: inline-block;
    min-width:40% ;
    margin-right: 10px;
}
.btn_wrapper{
    display: inline-block;

}

.message{
    font-weight: bold;
    color: darkgreen;
    font-size: 16px;
    text-align: center;
}