.wrapper{
    background-color:  rgba(255, 165, 0, 0.85);
    width: 50%;
    margin: 40px auto;
    padding: 30px;
    box-shadow: 2px 2px rgba(255, 165, 0, 0.8), -8px 0 0.4em #c7a946;
}
.title{
    text-align: center;
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;

    color: #2b52d7;
}

.text_box_wrapper{
   margin: 20px auto;
   text-align: center;
}

.text_box{
    display: block;
    margin: 30px auto;
    background: #FFFFFF;
    border: 3px solid #2662B3;
    box-sizing: border-box;
    border-radius: 20px;
    font-size: 16px;
    width: 300px;
    height: 40px;
    padding-left: 20px;
}

.button_enter{
    text-decoration: none;
    display: block;
    background: #2662B3;
    border-radius: 20px;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    border: 1px solid #2662B3;
    width: 100px;
    height: 30px;   
    color: #FFFFFF;
    text-align: center;
    margin: 10px auto;

    cursor: pointer;
}
.error_row{
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    color: #73240e;

    text-align: center;
    margin-top: -20px;
}

.error_row_empty{
    height:20px;
    width: 100px;
    margin-top: -10px;

}

@media(max-width:340px){
    .text_box{
        width: 180px;
    }
    
}