.wrapper{
    max-width: 700px;
    padding: 10px;
    margin: 30px auto;
    background-color: orange;
    border-radius: 20px;
}
.title{
    font-weight: bold;
    font-size: 26px;
    margin:10px 0 0 20px;
}
.title_name{
    font-weight: bold;
}
.wrapper_game{
    display: inline-block;

}

.wrapper_element{
    display: block;
    vertical-align: middle;
    margin-top: 10px;
    background-color: rgba(252, 229, 1, 0.41);
    border-radius: 20px;
    padding: 5px;
}
.title_element{
    font-weight: bold;

}
.remove_question{
    cursor: pointer;
    display: inline-block;
    width: 16px;
    text-align: center;
    line-height: 16px;
    padding: 8px;
    height: 16px;
    border-radius: 20px;
    margin-left: 10px;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.75);
    vertical-align: middle;
}
.wrapper_element div{
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
}
.wrapper_element textarea{
    display: inline-block;
    max-width: 550px;
    width: 220px;
    vertical-align: middle;
    margin-left: 10px;
    border-radius: 10px;
    padding: 5px;
}
.btn_view_location{
    margin: 10px 20px;
    border: 2px solid darkgreen;
    color: #FFFFFF;
    background-color: green;
    height: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
}

.locations{
    text-align: center;
}
.wrapper_location,
.wrapper_location_active{
    display: inline-block;
    border: 2px solid silver;
    border-radius: 10px;
    margin: 10px 0 0 20px;
    padding-left: 20px;
    width: 160px;
    font-weight: bold;
    cursor: pointer;
}
.wrapper_location_active{
    background-color: rgba(0, 128, 0, 0.32);
}
.next_menu{
    margin: 10px 0;
    height: 30px;
    line-height: 30px;
    color: #FFFFFF;
    background-color: #3b3a3a;
    padding-left: 20px;
}

.select_location_title{
    font-size: 16px;
    font-weight: bold;
    margin-left: 20px;
}

.score_setting{
    color: #FFFFFF;
    background-color: #3b3a3a;
    margin: 20px 0;
    padding: 10px 20px;
}
.wrapper_game_score{
    display: inline-block;
}

.wrapper_game_score div{
    font-size: 16px;
    font-weight: bold;

    text-align: center;
}

.wrapper_questions{
    display: block;
    margin-top: 5px;
}

.title_question{
    display: inline-block;
    width: 16px;
    text-align: center;
    line-height: 16px;
    padding: 8px;
    height: 16px;

    margin-left: 10px;
    font-weight: bold;
    /*background-color: rgba(255, 255, 255, 0.38);*/
    vertical-align: middle;
}

.btn_remove{
    /*float: right;*/
}

.check_box{
    display: inline-block;
    vertical-align: middle;
   height: 20px;
    width: 30px;
}

.input_box{
    width: 250px;
}