.title{
    margin: 20px auto;
    background-color: rgba(255, 255, 255, 0.92);
    border: 2px solid rgba(17, 43, 138, 0.34);
    border-radius: 10px;
    padding: 10px;
    max-width: 700px;
    font-weight: bold;
    font-size: 26px;
    text-align: center;
}

.btn_add_ser{
    border: 2px solid green;
    background-color: green;
    border-radius: 20px;
    color: white;
    width: 150px;
    margin: 10px auto;
    text-align: center;
    cursor: pointer;
}

.btn_wrapper{
    text-align: center;
}