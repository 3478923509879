.result_table{
    margin: auto;
}

.table_row {
    font-size: 14px;
    border-collapse: collapse;
    text-align: center;

}
.table_row th, td:first-child {
    background: rgba(255, 165, 0, 0.89);
    color: #000000;
    padding: 10px 20px;
}
.table_row th, td {
    border-style: solid;
    border-width: 0 1px 1px 0;
    border-color: #ababab;
    /*max-width: 200px;*/
}
.table_row td {
    background: rgba(255, 255, 255, 0.91);
}
.table_row th:first-child, td:first-child {
    text-align: left;
}
.table_row_wrapper{
    text-align: left;
}