.setting_icon{
    width: 30px;
    position: absolute;
    right: 10px;
    top:12px;


    cursor: pointer;
}
.error_user{
    margin: 20px;
    font-size: 26px;
    font-weight: bold;
    color: #112B8A;
}

.not_games{
    margin:40px auto;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color:#fc4544
}