.wrapper{
    border:2px solid #112B8A;
    border-radius: 20px;
    margin: 20px auto;
    padding: 10px 20px;
    max-width: 500px;
    background-color:  rgba(255, 165, 0, 0.8);
    min-height:180px ;
}

.delete_text{
    height: 24px;
    color: red;
}

.name{
    font-weight: bold;
    font-size: 20px;
}
.title_wrapper{
    width: 70%;
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
}
.qr_wrapper{
    width: 30%;
    display: inline-block;
    text-align: center;
    margin-top: 20px;
}

.btn_wrapper{
    margin-top: 30px;
    text-align: center;
}

@media(max-width:455px){
    .title_wrapper{
        width: 100%;
        display: block;
    }
    .qr_wrapper{
        display: block;
        width: 100%;
        margin-top: 20px;
        float: none;
        text-align: center;
    }
    .btn_wrapper{
        margin-top:0;
        text-align: center;
    }

}