.wrapper{
    display: inline-block;
}
.wrapper_nav{

}

.item_nav{
    display: inline-block;
    margin: 20px;
    border: 1px solid blue;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
}