.wrapper{
    /*background-color: rgba(255, 255, 255, 0.95);*/
    background-color: rgba(255, 192, 79, 0.94);
    font-weight: bold;
    width: 90%;
    margin:50px auto;
    border-radius: 20px;
}

.wrapper_btn{
    text-align: center;
    margin-top: 20px;
}

.error_message{
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #860000;
}