.wrapper{
    /*position: absolute;*/
    padding: 2px;
    /*top: calc(100vh - 55% - 190px);*/
    /*left: calc(50% - 160px);*/
    /*margin: 30px auto;*/
    text-align: center;
    background-color: rgba(255, 192, 79, 0.94);
    width: 320px;
    border-radius: 20px;
    margin: 50px auto ;
}
.wrapper svg{
    width: 150px;
    height: 150px;
}
.question_number{
    text-align: left;
    color: rgba(7, 7, 7, 0.84);
    padding: 10px;
    /*margin-bottom: 10px;*/
    border-radius: 10px;
}
/*.wrapper_answer{*/
/*    padding: 0 5px;*/
/*    background-color: rgba(255, 255, 255, 0.2);*/
/*}*/
.answer{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 24px;
    padding: 10px 20px;
}
.emoji{
    margin: 10px 5px;
    padding: 10px 0 10px 0;
    background-color: rgba(215, 215, 215, 0.49);
    box-shadow: 0 10px 10px rgba(0,0,0,0.25), 0 10px 100px rgba(0,0,0,0.22);
    border: 0.5px solid rgba(255, 255, 255, 0.66);
    border-radius: 20px;
}

.compitents_text{
    text-align: left;
    font-size: 20px;
    padding-left: 10px;
    font-weight: bold;
}
.empty_grade{
    color: darkred;
    height: 20px;
    margin: 10px auto;
    font-size: 18px;
    font-weight: bold;

}

/*.emoji div{*/
/*    display: block;*/
/*    margin: auto;*/
/*    width: 320px;*/
/*}*/
.game_title{
    text-align: left;
    color: rgba(7, 7, 7, 0.84);
    padding: 10px 10px;
    border-radius: 10px;

}
.game_text_ask{
    margin: 10px 10px 40px 10px;
    font-size: 20px;
    text-align: left;
    font-weight: bold;
}
.answer_wrapper{

}