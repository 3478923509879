.wrapper{
    border:2px solid #112B8A;
    margin: 20px auto;
    padding: 10px;
    border-radius: 20px;
    max-width: 700px;
    background-color: orange;
}
.new_password_form{
    background-color: rgba(82, 82, 82, 0.06);
    border-radius: 10px;
    border:2px solid silver;
}
.login{
    font-weight: bold;
    font-size: 20px;
}
.string_name{
    font-weight: bold;
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.59);
}
.type_user,
.type_admin{
    width: 150px;
    height: 30px;
    line-height: 30px;
    float: right;
    text-align: center;
    background-color: #2145cb;
    border-radius: 20px;
    color: #FFFFFF;
}
.type_user{
    background-color: #D6431A;
}

.qr_wrapper{
    text-align: center;
}
.qr_wrapper canvas{
    vertical-align: middle;
}
.qr_wrapper div{
    display: inline-block;
    vertical-align: middle;
}