.wrapper{
    text-align: center;
}
.empty_active,
.empty{
    display: inline-block;
    width: 20px;
    border: 2px solid #463500;
    color: #463500;
    margin: 5px 10px;
    line-height: 20px;
    font-size: 26px;
    border-radius: 20px;
    padding: 8px;
    font-weight: bold;
    cursor: pointer;
}
.empty_active{
    background-color: rgba(101, 194, 57, 0.94);
    color: white;
}

.empty:hover{
    background-color: rgba(101, 194, 57, 0.94);
    color: white;
}