
.wrapper{
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    right:50px;
    top:0;
    background-color: rgba(255, 255, 255, 0.92);

    font-size: 14px;
    border-radius: 10px;

}
.item_nav_active,
.item_nav{
    display: inline-block;
    border: 2px solid #112B8A;

    border-radius: 10px;
    text-align: center;
    margin: 10px;
    cursor: pointer;
    padding: 5px;
    font-weight: bold;
    min-width: 80px;
}

.item_nav_active{
    background-color: #5269fc;
    border-color: #112B8A;
    color: #FFFFFF;
}