.icon_wrapper{
    display: inline-block;
    vertical-align: middle;
}
.setting_play,
.setting{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;

    cursor: pointer;
    transform: scale(-1, 1)
}
.question_text{
    font-weight: normal;
}
.wrapepr_game{
    margin: 20px;
    padding: 20px;
    background-color: rgba(255, 165, 0, 0.8);
    border-radius: 20px;
    font-size: 16px;
    font-weight: bold;
}
.wrapper_answers{
    margin: 20px;
    padding: 20px;
    background-color: rgba(255, 165, 0, 0.8);
    border-radius: 20px;
    font-size: 16px;
    font-weight: bold;

}

.active_location, .location{
    padding: 5px 20px;
    border-radius: 10px;
    background-color: rgba(252, 229, 1, 0.59);
    margin-top: 10px;
}

.active_location{
    background-color: rgba(133, 253, 62, 0.59);
}

.wrapper_btn{
    text-align: center;
}

.number_location{
    font-weight: bold;
}

.wrapper_question{
   font-weight: normal;
}