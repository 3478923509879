.wrapper{
    border:2px solid #112B8A;
    border-radius: 20px;
    margin: 20px auto;
    padding: 10px;
    max-width: 700px;
    background-color: orange;
    min-height:220px ;
}

.delete_text{
    height: 24px;
    color: red;
}

.name{
    font-weight: bold;
    font-size: 20px;
}

.qr_wrapper{
    float: right;
    text-align: center;
}